/* Profile Icon */
.profile-icon {
    width: 50px;
    height: 50px;
    background-color: #007bff; /* Blue background for profile icon */
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
}

/* Button styles */
.btn-outline-primary {
    border-color: #007bff;
    color: #007bff;
}

.btn-outline-primary.active, .btn-outline-primary:active {
    background-color: #007bff;
    color: white;
}
